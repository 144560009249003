import { MAILING_DNS_SETTINGS_API } from 'modules/profile/mailing-settings/api/mailing-settings-domain-api'
import {
  MailingSettingsDomainsApiInterface,
} from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { SwrPaginationInterface } from 'shared/types/pagination-interface'
import useSWR from 'swr'
import { getPaginationQueryString } from 'shared/utils/api/get-pagination-query-string'

interface useMailingDnsSettings extends SwrPaginationInterface {}

export function useMailingDnsSettings({ pagination,limit }: useMailingDnsSettings) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    MailingSettingsDomainsApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: dnsMailingSettings, mutate } = useSWR(() => user && `${MAILING_DNS_SETTINGS_API}?${getPaginationQueryString({pagination,limit})}`, fetcher)

  return { dnsMailingSettings, mutate }
}

import {
  CreateSenderEmailAddressInterface,
  SenderEmailAddressId,
  SenderEmailAddressType,
} from 'modules/profile/mailing-settings/types/sender-email-address-interface'
import httpClient from 'shared/utils/http-client'

export const MAILING_SETTINGS_SENDER_EMAILS_API = '/api/dashboard/customer/mailing/sender-email'

export const removeSenderEmailAddress = (id: SenderEmailAddressId) =>
  httpClient.delete(`${MAILING_SETTINGS_SENDER_EMAILS_API}/${id}`)

export const resendSenderEmailAddress = (id: SenderEmailAddressId) =>
  httpClient.post<SenderEmailAddressType>(`${MAILING_SETTINGS_SENDER_EMAILS_API}/${id}/resend`)

export const createSenderEmailAddress = (data: CreateSenderEmailAddressInterface) =>
  httpClient.post(MAILING_SETTINGS_SENDER_EMAILS_API, data)

export const getCheckIfSenderEmailUsedApi = (id: SenderEmailAddressId) =>
  `${MAILING_SETTINGS_SENDER_EMAILS_API}/${id}/is-used`

import {
  MAILING_DNS_SETTINGS_API,
  removeDomain,
} from 'modules/profile/mailing-settings/api/mailing-settings-domain-api'
import DnsSettingsModalWithButton from 'modules/profile/mailing-settings/components/dns-settings-modal-with-button'
import DomainTableSkeleton from 'modules/profile/mailing-settings/components/domain-table-skeleton'
import SetupDomainAuthentication from 'modules/profile/mailing-settings/components/setup-domain-authentication'
import { DomainStatusEnum } from 'modules/profile/mailing-settings/enums/domain-status-enum'
import { useMailingDnsSettings } from 'modules/profile/mailing-settings/hooks/use-mailing-dns-settings'
import { MailingSettingsDomainInterface } from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import React from 'react'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import { usePagination } from 'shared/route-query/hooks/use-pagination'

function DomainTable() {
  const { t } = useLocoTranslation()

  const { setPagination, pagination, setLimit, limit } = usePagination(true)

  const { dnsMailingSettings } = useMailingDnsSettings({ pagination, limit })

  const updateData = useUpdateData({
    setPagination,
    api: MAILING_DNS_SETTINGS_API,
  })

  const mailingSettingsDomainColumns: ColumnDefinitionInterface<MailingSettingsDomainInterface>[] =
    [
      {
        key: 'domainName',
        header: t('dashboard.settings.mailing.setup_domain_modal_domain_name_title'),
        widthPercent: 40,
        onRender: domainData => (
          <div className="truncate">
            <Tooltip mode="overflow" label={domainData.domainName}>
              <span className="truncate">{domainData.domainName}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        key: 'status',
        header: t('global.status'),
        widthPercent: 30,
        onRender: domainData => <div>{t(DomainStatusEnum[domainData.status])}</div>,
      },
      {
        key: 'dns-settings',
        header: t('dashboard.settings.mailing.dns_settings_title'),
        widthPercent: 30,
        onRender: domainData => <DnsSettingsModalWithButton domainData={domainData} />,
      },
    ]

  const onRemoveDomain = async (id: number) => {
    await removeDomain(id)
    await updateData(false, dnsMailingSettings)
  }

  return (
    <>
      {dnsMailingSettings ? (
        <div className="border border-gray/30 rounded-md pb-4">
          <Table
            actions={{
              remove: {
                onChange: dns => onRemoveDomain(dns.id),
              },
            }}
            data={dnsMailingSettings}
            columns={mailingSettingsDomainColumns}
            limiter={{ limit, setLimit }}
            paginator={{ pagination, setPagination }}
            caption={t('dashboard.settings.mailing.dns_settings_domain_authentication_title')}
            emptyListWithTitles
          />
          <SetupDomainAuthentication
            updateDomainTable={async () => {
              await updateData(false, dnsMailingSettings)
            }}
          />
        </div>
      ) : (
        <DomainTableSkeleton />
      )}
    </>
  )
}

export default DomainTable

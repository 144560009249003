import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { MAILING_SETTINGS_SENDER_EMAILS_API } from '../api/mailing-settings-sender-emails-api'
import {
  ReplaceSenderEmailAddressInterface,
  SenderEmailAddressId,
} from '../types/sender-email-address-interface'

export const useSenderEmailAddressActions = () => {
  const { fetcher, isFetching } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.put,
    any,
    ReplaceSenderEmailAddressInterface
  >({
    method: RequestMethodsEnum.put,
  })

  const replaceEmail = async (id: SenderEmailAddressId, email: string) =>
    fetcher(`${MAILING_SETTINGS_SENDER_EMAILS_API}/${id}`, { senderEmailAddress: email })

  return { replaceEmail, isFetching }
}

import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { getCheckIfSenderEmailUsedApi } from '../api/mailing-settings-sender-emails-api'
import { SenderEmailAddressId } from '../types/sender-email-address-interface'

export function useCheckIfSenderEmailUsed() {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, { isUsed: boolean }>(
    {
      method: RequestMethodsEnum.get,
    },
  )

  const checkIfSenderEmailUsed = (id: SenderEmailAddressId) =>
    fetcher(getCheckIfSenderEmailUsedApi(id))

  return { checkIfSenderEmailUsed }
}
